const headerNav = {
	button: document.querySelector('#toggle_mobile_nav'),
	nav: document.querySelector('#nav'),
	toggle: function() {
		this.button.switchAttribute('state', 'open', 'closed');
		this.nav.switchAttribute('state', 'open', 'closed');
	},
	init: function() {
		this.button.addEventListener('click', () => this.toggle());
	}
};

export const initMobileNav = () => headerNav.init();