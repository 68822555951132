import './polyfills.js';

import { initMobileNav } from './header.js';
import { initHeroCaro, initHomeMap } from './home';
import { contactSubmit, initContactMap } from './contact.js';

initMobileNav();

if(document.querySelector('#home_hero')) {
	initHeroCaro();
}

if(document.querySelector('#home_map')) {
	initHomeMap();
}

if (document.querySelector('#contact_map')) {
	initContactMap();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'address', 'phone', 'refer', 'subject', 'message']
	});
}

const tabSystem = (function(){
	let my = {},
		tabs = document.querySelectorAll('.tab_title');

	function _assignListeners(){
		[].forEach.call(tabs, function(el){
			el.addEventListener('click', _switchToTab);
		});
	}
	
	function _switchToTab(e){
		let tab = e.currentTarget;
		document.querySelector('.tab_title.active').classList.remove('active');
		tab.classList.add('active');

		document.querySelector('.tab_content.active').classList.remove('active');
		document.getElementById(tab.dataset.tab).classList.add('active');
	}

	my.init = function(){
		if(tabs.length){
			_assignListeners();
		}
	};

	return my;
})();

tabSystem.init();