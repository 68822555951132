import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

const homeHeroCaro = { 
	hero: document.querySelector('#home_hero'),
	images: [
		{src: "/dist/images/background/img_hero_slide_1.jpg", alt: ""},
		{src: "/dist/images/background/img_hero_slide_2.jpg", alt: ""},
		{src: "/dist/images/background/img_hero_slide_3.jpg", alt: ""},
		{src: "/dist/images/background/img_hero_slide_4.jpg", alt: ""},
	],
	init: function() {
		AutomatitCarousel({
			element: this.hero,
			images: this.images,
			imagesAsBackgrounds: true,
			showDots: true,
			useChevrons: false
		});
	}
};

const homeMap = {
	theMap: {
		instance: null,
		ele: document.querySelector('#home_map')
	},
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '.map_data',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<span class="map_pin"><img src="/dist/images/icons/ic_map_pin.svg" alt="" /></span>`
				};
			}
		});
	}
};

export const initHeroCaro = () => homeHeroCaro.init();
export const initHomeMap = () => homeMap.init();